import { Dropdown, Offcanvas } from 'bootstrap'
import { getCookie, isCookie, getPreferences } from './helper';

/* custom Modal method */

/* Modal.prototype.clear = function() {
	this._element.remove()
	this.dispose()
}; */

/* custom Dropdown method */

Dropdown.prototype.openCurrent = function() {
  if (this._menu.querySelector('a[aria-current]')) {
    this._element.setAttribute('aria-current', true);
    this._element.setAttribute('aria-expanded', true);
    this._element.classList.add('show');
    this._menu.classList.add('show');
  }
};

/* head-skip: focus on the search without scrolling */

(function() {
	document.querySelector('.head-skip-link[href="#search"]')
    .addEventListener('click', evt => {
      evt.preventDefault();
      document.querySelector('.head-search-input').focus();
  })
})();

/* config form: set init value */

(function() {
  for (const name in document.documentElement.dataset) {
    if (name in document.config) {
      if (name == 'mode') {
        document.config.mode.value = localStorage.mode || 'auto';
      } else {
        document.config[name].value = document.documentElement.dataset[name];
      }
    }
  }
})();

/* config form: on change */

(function() {
  document.config.addEventListener('change', evt => {
    if (evt.target.name == 'mode') {
      if (evt.target.value == 'auto') {
        localStorage.removeItem('mode');
        document.documentElement.dataset.mode = matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      } else {
        localStorage.mode = evt.target.value;
        document.documentElement.dataset.mode = evt.target.value;
      }
    } else if (evt.target.name in document.documentElement.dataset) {
      localStorage[evt.target.name] = evt.target.value;
      document.documentElement.dataset[evt.target.name] = evt.target.value;
    }
  })
})();

/* prefers color scheme: set change value */

(function() {
  matchMedia('(prefers-color-scheme: dark)')
    .addEventListener("change", evt => {
      document.documentElement.dataset.mode = localStorage.mode == 'dark' || (!('mode' in localStorage) && evt.matches) ? 'dark' : 'light';
    })
})();

/* toggle title of config primary color for theme mode */

/* (function() {
  function setTitleAttrForMode() {
    let is_light = document.documentElement.dataset.mode == 'light';
    document.config.primary.forEach(el => {
      if (el.value == 'yellow') {
        el.title = is_light ? 'Brązowy' : 'Żółty';
      }
    });
  }
  // set init title
  setTitleAttrForMode();
  //
  const observer = new MutationObserver(mutationList => {
    for (const mutation of mutationList) {
      if (mutation.attributeName === 'data-mode') {
        setTitleAttrForMode();
      }
    }
  });
  //
  observer.observe(document.documentElement, {
    attributes: true
  });
})(); */

/* head-search: toggle, clear */

(function() {
  const search = document.querySelector('.head-search');
  const input = document.querySelector('.head-search-input');
  const toggle = search.querySelector('[data-bs-toggle]');
	new Dropdown(toggle, {
		display: 'static',
		autoClose: 'outside'
	});
  // toggle show, hide
  toggle.addEventListener('show.bs.dropdown', () => {
    search.classList.add('show');
  })
  toggle.addEventListener('hide.bs.dropdown', () => {
    search.classList.remove('show');
  })
  // clear input
  search.clear.addEventListener('click', () => {
    input.value = '';
    input.focus();
  })
})();

/* main-nav: dropdowns */

(function() {
	const mainnav = document.querySelector('.main-nav');
	const toggle = mainnav.querySelectorAll('[data-bs-toggle]');
  const dropdowns = [...toggle].map(el => {
    // create dropdown instances
    const dropdown = new Dropdown(el, {
      display: 'static',
      autoClose: false
    });
    //
    dropdown.openCurrent()
    //
    return dropdown;
  });
	// hide siblings dropdown before shown next
	mainnav.addEventListener('show.bs.dropdown', () => {
		dropdowns.forEach(dropdown => {
      if (dropdown._element.classList.contains('show')) {
        dropdown.hide()
      }
    });
	})
})();

/* change email addresses */

(function() {
  document.querySelectorAll('a[data-send_to]')
    .forEach(el => {
      let email = el.dataset.send_to.replace(/\{([0-9]+)na([0-9]+\])/,'@');
      el.textContent = email;
      el.href = `mailto:${email}?subject=${el.dataset.subject}`;
      el.removeAttribute('data-send_to');
      el.removeAttribute('data-subject');
    })
})();

/* post-printer */

(function() {
  const printer = document.querySelector('.post-printer');
  if (!printer) return;
  printer.addEventListener('click', () => {
    window.print()
  })
})();

/* linked grid row */

(function() {
  const tbody = document.querySelector('.c-grid-table--body');
  if (!tbody) return;
  tbody.addEventListener('click', evt => {
    if (evt.target.nodeName == 'A') return;
    evt.target.parentNode.querySelector('a').click()
  })
})();

/* dialog trigger */

(function () {
  const triggers = document.querySelectorAll('[data-dialog]')

  if (!triggers.length) return;

  triggers.forEach(el => {
    el.addEventListener('click', evt => {
      const dialog = document.getElementById(el.dataset.dialog)
      if (!dialog) return;
      dialog.open ? dialog.close() : dialog.showModal()
    })
  })

})();

/* dialog outdated 08.2023 */

(function () {
  const dialog = document.getElementById('outdated')

  if (!dialog || sessionStorage.hideOutdated) return;

  const form = document.forms.outdated;
  
  if (document.getElementById('cookies')) {
    const cookies = document.getElementById('cookies')
    cookies.addEventListener('close', evt => {
      dialog.showModal()
    })
  } else {
    dialog.showModal()
  }

  form.addEventListener('change', evt => {
    if (evt.target.checked) {
      sessionStorage.hideOutdated = true
    } else {
      delete sessionStorage.hideOutdated
    }
  })
})();

/* dialog cookies & share preferences 08.2023 */

(function() {
  const dialog = document.getElementById('cookies')
  
  if (!dialog) return;

  isCookie('accept') ? dialog.remove() : dialog.showModal();

  dialog.addEventListener('close', evt => {
    const maxAge = 14 * 86400; // 14 days
    const form = document.forms.cookies;
    document.cookie = `accept=${form.preferences.checked}; Path=/; Max-Age=${maxAge};`;
  })
})();

/* share preferences 08.2023  */

(function () {
  if (getCookie('accept') !== 'true') return;

  const preferences = getPreferences()
  const preferencesStr = JSON.stringify(preferences)
  
  if (localStorage.sharedPreferences === preferencesStr) return

  fetch(`/site/statystyki?${(new URLSearchParams(preferences)).toString()}`)
    .then(() => { localStorage.sharedPreferences = preferencesStr })
    .catch(err => { console.error('Error:', err) })
})();