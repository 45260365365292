export function getCookie(name) {
  return document.cookie.split('; ').find((row)=>row.startsWith(`${name}=`))?.split('=')[1];
}

export function isCookie(name) {
  return document.cookie.includes(`${name}=`)
}

export function getPreferences() {
  const names = ['mode','primary','size']
  const result = {}
  names.forEach(name => {
    result[name] = document.documentElement.dataset[name] || null
  });
  return result
}